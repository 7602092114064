
















import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  components: { OPrivacyList: () => import('@/components/organisms/globalTools/terms/privacy/o-privacy-list.vue')},

  setup(){

    return {}
  }
})
